import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
// components
import AuthorInfo from './AuthorInfo';

const ReadMore = styled.div`
  font-size: 1.1em;
  font-weight: 500;
  margin: 0 1rem 1rem 1rem;
  transition: .3s;
  position: relative;
  width: 100px;
  margin-left: auto;
  &:before {
    transition: .2s;
    content: "";
    height: 18px;
    width: 0;
    display: block;
    position: absolute;
    left: -4px;
    right: -12px;
    bottom: -8px;
    background-color: ${props => props.theme.colors.rasPrimary};
    opacity: .7;
    z-index: -1;
  }
`
const StyledImg = styled(Img)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`

const Post = styled.li`
  position: relative;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 2px;
  margin: 0 0 1em 0;
  width: 100%;
  transition: background 0.2s;
  background: #fff;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
    ${ReadMore} {
      transform: translate(-2px, -2px);
    }
    ${ReadMore}::before {
      width: 100%;
    }
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    ${StyledImg}.gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    }
  }
`

const Body = styled.div`
  padding: 16px 0 6px 0;
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 500;
  text-transform: capitalize;
  margin: 1rem 1rem 0.5rem 1rem;
`

const InfoWrapper = styled.h4`
  margin: ${props => (props.margin ? props.margin : '0 1rem 1.5rem 1rem')};
  color: gray;
`

const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`

const Card = ({ slug, heroImage, title, publishDate, body, author, ...props }) => {
  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <StyledImg fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
            <Body>
              <InfoWrapper margin={'0 1rem'}>
                <AuthorInfo author={author} />
              </InfoWrapper>
              <Title>{title}</Title>
              <InfoWrapper>
                {`${publishDate} • ${body.childMarkdownRemark.timeToRead} min read`}
              </InfoWrapper>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.excerpt,
                }}
              />
              <ReadMore>Read More</ReadMore>
            </Body>
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
